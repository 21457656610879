@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

 
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
 
body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  background-color: white;
  font-family: Poppins !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@layer base {
  .heading{
    @apply text-lg font-medium text-black;
  }
  .sub-heading{
    @apply text-xs text-gray-600 mt-1;
  }
  .smallText {
    @apply text-xs font-light opacity-80;
  }
  .bigText {
    @apply text-sm font-medium;
  }
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: hsla(265, 100%, 25%, 1) transparent hsla(265, 100%, 25%, 1) transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

 
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #7789c4; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
  border: 2px solid #f1f1f1; /* creates padding around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #7789c4; /* color of the thumb when hovered */
}
